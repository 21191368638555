<template>

  <!-- Assignments View-->
  <j-page
    title="Assignments"
    :loading="loading"
    id="assignments-view"
  >
    <v-col>

      <!-- Requested Opps -->
      <RequestsTable />

      <!-- Confirmed Assignments -->
      <AssignmentTable
        heading="Confirmed Assignments"
        :opps="assigned"
        @draft="handleDraft"
        @cancel="handleCancelAssignment"
      />

      <!-- Proposed Assignments -->
      <AssignmentTable
        heading="Proposed Assignments"
        :opps="proposed"
        @confirm="handleConfirm"
        @cancel="handleCancelAssignment"
      />

      <!-- Comment modal for retiring assignments -->
      <CancelAssignmentDialog />

    </v-col>
  </j-page>
</template>

<script>

// Components
import AssignmentTable from '@/components/widgets/Opps/AssignmentTable'
import CancelAssignmentDialog from '@/components/Opps/CancelAssignmentDialog'
import RequestsTable from '@/components/Opps/RequestsTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('opps')

export default {
  name: 'Assignments',
  // -------------
  // Components ==
  // -------------
  components: {
    AssignmentTable,
    RequestsTable,
    CancelAssignmentDialog
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.fetchAssignments()
    })
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Assignments'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'opp',
      'requested',
      'assigned',
      'proposed'
    ]),
    ...mapGetters([
      'loading'
    ]),
    commentDialogVisible () {
      return this.$store.state.comments.commentDialogVisible
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchAssignments',
      'fetchOpp',
      'confirmOpp',
      'createDraft'
    ]),
    handleDraft (opp) {
      this.createDraft(opp)
    },
    handleConfirm (opp) {
      this.confirmOpp(opp)
    },
    handleCancelAssignment (opp) {
      this.fetchOpp(opp.get('tag19')).then(
        this.$dialog.open('cancel-assignment')
      )
    }
  }
}
</script>
