<template>

  <!-- Requests Table -->
  <j-table
    :listing="requested"
    items="opportunities"
    heading="Requested"
    :show-count="true"
    class="requests-table"
  >

    <!-- Header -->
    <template #header>
      <tr>
        <th width="15%">Created</th>
        <th width="15%">Section</th>
        <th width="40%">Title</th>
        <th width="30%">Requesters</th>
      </tr>
    </template>

    <!-- Listing -->
    <template #listing>
      <tr
        v-for="opp in requested.getModels()"
        :key="opp.tag19"
      >

        <!-- Date -->
        <td> {{ opp.created_at | shortDate }} </td>

        <!-- Section -->
        <td>
          <j-label :section="opp.section" />
        </td>

        <!-- Title -->
        <td>
          <j-icon
            v-if="opp.assignment_slug"
            icon="check"
            class="mr-1"
          />
          <j-label
            :title="opp.releaseDate"
            color="primary"
            small
            class="mr-1"
          />
          <router-link :to="{ name: 'opps-details', params: { tag19: opp.tag19 }}">
            <strong>{{ opp.title | truncate(50) }}</strong>
          </router-link>
        </td>

        <!-- Actions -->
        <td>
          <v-list
            dense
            color="transparent"
          >

            <!-- Requester List -->
            <v-list-item
              v-for="request in opp.requests"
              :key="request.id"
              :title="`${request.assigned ? 'Unassign' : 'Assign to'} ${request.requester_name}`"
              @click="handleAssign(opp, request.id)"
            >
              <v-list-item-content>

                <!-- Requester Chip -->
                <j-label
                  :title="request.requester_name"
                  :solid="request.assigned"
                  @click="handleAssign(opp, request.id)"
                />

              </v-list-item-content>
            </v-list-item>
          </v-list>
        </td>
      </tr>
    </template>
  </j-table>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('opps')

export default {
  name: 'RequestsTable',
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'requested'
    ]),
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'assignOpp'
    ]),
    // Handlers
    handleAssign (opp, request_id) {
      this.assignOpp({ opp, request_id })
    }
  }
}
</script>
