<template>

  <!-- Assignment Table Widget -->
  <j-table
    :listing="opps"
    items="opportunities"
    :heading="heading"
    :subhead="subhead"
    :count="count"
    :show-pagination="false"
    class="assignment-table-widget"
  >

    <!-- Actions -->
    <template #actions>
      <slot name="actions" />
    </template>

    <!-- Header -->
    <template
      v-if="showTableHeader"
      #header
    >
      <tr>
        <th v-if="show('date')">
          {{ dateLabel }}
        </th>
        <th>Section</th>
        <th width="90%">
          Title
        </th>
        <th v-if="show('assignee')">
          Assignee
        </th>
        <th>&nbsp;</th>
      </tr>
    </template>

    <!-- Listing -->
    <template #listing>
      <tr
        v-for="opp in opps.getModels()"
        :key="opp.tag19"
      >
        <!-- Date -->
        <td
          v-if="show('date')"
          style="white-space: nowrap; v-align: top;"
        >
          {{ opp[dateField] | shortDate }}
        </td>

        <!-- Section -->
        <td>
          <j-label :section="opp.section" />
        </td>

        <td width="90%">

          <!-- Release Date -->
          <j-label
            :title="opp.releaseDate"
            color="primary"
            small
            class="mr-1"
          />

          <!-- Title -->
          <router-link :to="{ name: 'opps-details', params: { tag19: opp.tag19 }}">
            <strong>{{ opp.title | truncate(50) }}</strong>
          </router-link>

        </td>

        <!-- Assignee -->
        <td
          v-if="show('assignee')"
          style="white-space: nowrap;"
        >
          {{ opp.assignee.name }}
        </td>

        <!-- Proposed Actions -->
        <td style="white-space: nowrap;">
          <!-- For proposed: Confirm Proposed Assignment -->
          <j-button
            v-if="opp.stage == 'proposed'"
            type="icon"
            icon="check"
            :processing="opp.saving"
            title="Confirm proposed assignment"
            @action="handleConfirm(opp)"
          />

          <!-- For assigned: Submit Draft for Assignment -->
          <j-button
            v-else-if="opp.stage == 'assigned'"
            type="icon"
            icon="copy"
            :processing="opp.saving"
            title="Submit draft for assignment"
            @action="handleDraft(opp)"
          />

          <!-- Cancel Assignment -->
          <j-button
            type="icon"
            icon="times"
            :processing="opp.saving"
            title="Decline / Cancel proposed assignment"
            @action="handleCancel(opp)"
          />

        </td>
      </tr>
    </template>
  </j-table>
</template>

<script>

export default {
  name: 'AssignmentTable',
  // -------------
  // Properties ==
  // -------------
  props: {
    opps: {
      type: Object,
      default: null
    },
    heading: {
      type: String,
      default: ''
    },
    subhead: {
      type: String,
      default: ''
    },
    count: {
      type: Boolean,
      default: true
    },
    hide: {
      type: Array,
      default: () => []
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    showTableHeader: {
      type: Boolean,
      default: true
    },
    dateLabel: {
      type: String,
      default: 'Published'
    },
    dateField: {
      type: String,
      default: 'published_at'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // UI
    columnCount () {
      return 5 - this.hide.length
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // UI
    show(column) {
      if (this.hide) {
        return !this.hide.includes(column)
      } else {
        return true
      }
    },
    // Handlers
    handleConfirm(opp) {
      this.$emit('confirm', opp)
    },
    handleCancel(opp) {
      this.$emit('cancel', opp)
    },
    handleDraft(opp) {
      this.$emit('draft', opp)
    },
  }
}
</script>
